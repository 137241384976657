.skill-icon{
    max-height: 48px;
    height: 2.25rem;
}

.resume-section{
    margin-top: 2%;
}

.skill-list{
    padding-left: 7vw;
    padding-right: 7vw;
}

.certification-logo{
    width: 5vw;    
}

.certification-text{
    padding-left: 2%;
}

.projectGif{
    max-width: 70vw;
    width: 80%;
}

.chunkText{
    max-width: 80ch;
    line-height: 2.5;
}

#dashboardImg{
    max-width: 85vw;
}

#st-home-image{
    max-width: 35vw;
}

#st-detail-image{
    max-width: 35vw;
}