.nav-icon{
    height: 5vh;
    align-self: center !important;
}

.nav-text{
    font-size: 1rem;
    letter-spacing: .1rem;
}


.nav-box{
    display: grid;
    justify-items: center;
    padding: 1rem;
    align-items: center;
    min-height: 10vh;
}

.nav{
    max-width: 90vw !important;
    margin: 0;
}